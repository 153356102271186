import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	titleSectionOne: {
		maxWidth: '250px',
	},
	imageWrap: {
		position: 'relative',
		width: '213px',
		height: '150px',
		margin: theme.spacing(0, 'auto', 1),
	},
	titleSectionTwo: {
		width: '100%',
		margin: theme.spacing(2, 0),
	},
}));

export default useStyles;
