import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	formSectionContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	formWrap: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	numberFieldWrap: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		marginBottom: theme.spacing(3),
	},
	countryCodeWrap: {
		borderRadius: '15px',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '60px',
		padding: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			height: '45px',
			marginRight: theme.spacing(1),
		},
	},
	numberInputFieldWrap: {
		width: '100%',
	},
	inputFieldRoot: {
		margin: 0,
	},
	inputFieldWrap: {
		borderRadius: '15px',
		margin: 0,
		height: '60px',
		[theme.breakpoints.down('sm')]: {
			height: '45px',
		},
	},
	inputField: {
		height: '100%',
	},
	otpFieldWrap: {
		display: 'flex',
		justifyContent: 'center',
	},
	otpTextWrap: {
		display: 'flex',
		justifyContent: 'center',
	},
	submitButtonWrap: {
		marginBottom: theme.spacing(2),
	},
	submitButton: {
		margin: theme.spacing(1, 0),
		borderRadius: '37px',
		textTransform: 'capitalize',
		color: theme.palette.common.white,
		background:
			'linear-gradient(263.25deg, rgba(245, 131, 32, 0.87) 0%, rgba(241, 105, 33, 0.987054) 81.82%, #D75312 109%)',
		boxShadow: '6.84606px 11.4101px 17.1151px rgba(208, 102, 8, 0.3)',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	addSpaceAfter: {
		'&::after': {
			content: '" "',
			whiteSpace: 'pre',
		},
	},
	hideNumberFieldArrows: {
		'& input[type=number]': {
			MozAppearance: 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
}));

export default useStyles;
