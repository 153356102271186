export const getImageForUserModal = (modalType) => {
	switch (modalType) {
		default:
			return {
				url: 'https://staging-azent-website-cms-uploads.s3.amazonaws.com/login_modal_logo_53da41bcd2.svg',
				alternativeText: modalType
					? modalType.toString().toUpperCase() + '_MODAL'
					: '',
			};
	}
};
