import { useDispatch, useSelector } from 'react-redux';

// components
import FormSection from './partials/formSection';
import TitleSection from './partials/titleSection';

// material
import { Close } from '@mui/icons-material';
import { Box, Dialog, Drawer, IconButton } from '@mui/material';

// utils
import { setUserModalData } from '@/store/actions';
import * as eventActions from '@/utils/analytics';

//style
import useStyles from './style';

export default function LoginSignupModal() {
	const { classes } = useStyles();
	const dispatch = useDispatch();

	const isMobile = useSelector((state) => state.common.isMobile);
	const modalType = useSelector((state) => state.userModal.data.modalType);

	const onClose = () => {
		eventActions.trackEvent('BUTTON_CLICKED', {
			section: modalType.toUpperCase() + '_MODAL',
			eventLabel: 'CLOSE_ICON',
			eventAction: 'CLICKED',
			eventCategory: 'USER_EVENT',
		});
		dispatch(setUserModalData({ modalType: null }));
	};

	let ModalComponent = Dialog,
		modalProps = {
			open: !!modalType,
			onClose,
			classes: { paper: classes.dialogPaper, root: classes.dialog },
		};

	if (isMobile) {
		ModalComponent = Drawer;
		modalProps.classes = {
			paper: classes.drawerPaper,
			root: classes.drawerRoot,
		};
		modalProps.anchor = 'right';
	}

	return (
		<ModalComponent {...modalProps}>
			<Box className={classes.closeIconWrap}>
				<IconButton onClick={onClose}>
					<Close fontSize="small" />
				</IconButton>
			</Box>

			<Box className={classes.modalBody}>
				<Box className={classes.titleSectionWrap}>
					<TitleSection />
				</Box>
				<Box className={classes.formSectionWrap}>
					{modalType ? (
						<FormSection />
					) : (
						<Box className={classes.formPlaceholder}></Box>
					)}
				</Box>
			</Box>
		</ModalComponent>
	);
}
