import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	dialog: {
		height: '100%',
		margin: 'auto',
	},
	dialogPaper: {
		borderRadius: '24px',
		maxWidth: '550px',
		width: '100%',
	},
	drawerRoot: {
		height: '100%',
	},
	drawerPaper: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: theme.spacing(0),
			height: '100%',
		},
	},
	closeIconWrap: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		margin: '10px',
		position: 'absolute',
		right: 0,
	},

	modalBody: {
		width: '80%',
		padding: theme.spacing(4, 1, 1, 1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3, 2),
			width: '100%',
		},
	},
	titleSectionWrap: {
		width: '80%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	formSectionWrap: {
		width: '100%',
		paddingBottom: theme.spacing(3),
	},
	formPlaceholder: {
		height: '203px',
		[theme.breakpoints.down('sm')]: {
			height: '188px',
		},
	},
}));

export default useStyles;
