import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	inputField: {
		borderRadius: '15px',
		maxWidth: '60px',
		minWidth: '40px',
		height: '60px',
		[theme.breakpoints.down('sm')]: {
			height: '45px',
			borderRadius: '5px',
		},
		[theme.breakpoints.down(425)]: {
			minWidth: '35px',
			height: '35px',
		},
	},
	textCenter: {
		textAlign: 'center',
	},
}));

export default useStyles;
