import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

// constants
import { textValues } from './constants';

// components
import OTPInput from '@/components/widgets/loginSignupModal/partials/otpField';
import WithAuthUtils from '@/components/widgets/loginSignupModal/withAuthUtils';

// material
import { Box, Button, TextField, Typography } from '@mui/material';

// util
import * as eventActions from '@/utils/analytics';

//styles
import useStyles from './style';

function FormSection({ values, errorTexts, otpSent, remainingTime, ...props }) {
	const { classes, cx } = useStyles();

	const modalType = useSelector((state) => state.userModal.data.modalType);
	const location = useSelector((state) => state.router.location);
	const textFieldRef = React.useRef(null);

	const trackEvent = (eventName, eventProperties) => {
		eventActions.trackEvent(eventName, {
			section: modalType.toUpperCase() + '_MODAL',
			...eventProperties,
		});
	};

	const setNumber = (e) => {
		props.setNumber(e.target.value);
	};

	const setOTP = React.useCallback(props.setOTP, []);

	const resendOTP = () => {
		trackEvent('BUTTON_CLICKED', {
			eventAction: 'CLICKED',
			eventCategory: 'USER_EVENT',
			eventLabel: textValues.buttonTextBeforeOTP,
		});
		props.resendOTP();
	};

	const sendOTP = () => {
		trackEvent('BUTTON_CLICKED', {
			eventAction: 'CLICKED',
			eventCategory: 'USER_EVENT',
			eventLabel: textValues.buttonTextBeforeOTP,
		});
		props.sendOTP();
	};

	const handleSubmit = async () => {
		trackEvent('BUTTON_CLICKED', {
			eventAction: 'CLICKED',
			eventCategory: 'USER_EVENT',
			eventLabel: textValues.resendOTPText,
		});
		props.handleSubmit();
	};

	const handleWheel = (e) => e.preventDefault();

	React.useEffect(() => {
		const element = textFieldRef.current;
		if (!element) return;

		element.addEventListener('wheel', handleWheel, {
			passive: false,
		});

		return () => {
			element.removeEventListener('wheel', handleWheel, {
				passive: false,
			});
		};
	}, [textFieldRef.current]);

	const FormFields = React.useMemo(() => {
		const textFieldProps = {
			id: 'username',
			name: 'username',
			type: 'number',
			variant: 'outlined',
			margin: 'dense',
			required: true,
			fullWidth: true,
			autoFocus: true,
			classes: {
				root: cx(classes.inputFieldRoot, classes.hideNumberFieldArrows),
			},
			InputProps: { className: classes.inputFieldWrap },
			inputProps: { className: classes.inputField, min: 0 },
			helperText: errorTexts.username || null,
			error: !!errorTexts.username,
			value: values.username,
			onChange: setNumber,
			onKeyDown: (e) => e.key === 'Enter' && e.preventDefault(),
			ref: textFieldRef,
		};

		const otpFieldProps = {
			className: classes.otpFieldWrap,
			onChangeOTP: setOTP,
			value: values.otp,
			autoFocus: true,
			length: 6,
			isNumberInput: true,
			inputClassName: classes.hideNumberFieldArrows,
			error: !!errorTexts.otp,
			helperText: errorTexts.otp || null,
		};

		return (
			<>
				<Box className={classes.numberFieldWrap}>
					<Box className={classes.countryCodeWrap}>
						{textValues.countryCodeText}
					</Box>
					<Box className={classes.numberInputFieldWrap}>
						<TextField {...textFieldProps} />
					</Box>
				</Box>
				{otpSent && <OTPInput {...otpFieldProps} />}
			</>
		);
	}, [errorTexts, otpSent, values]);

	const OTPSendText = React.useMemo(() => {
		if (!otpSent) return null;

		if (remainingTime !== 0) {
			return (
				<Typography>{`Resend OTP in ${remainingTime} seconds`}</Typography>
			);
		}

		return (
			<Typography>
				<Typography component="span" className={classes.addSpaceAfter}>
					{textValues.otpNotReceivedText}
				</Typography>
				<Typography
					component="span"
					color="primary"
					className={classes.cursorPointer}
					onClick={resendOTP}
				>
					{textValues.resendOTPText}
				</Typography>
			</Typography>
		);
	}, [otpSent, remainingTime, resendOTP]);

	return (
		<Box className={classes.formSectionContainer}>
			<form
				className={classes.formWrap}
				autoComplete={location.pathname.startsWith('/kiosk/') ? 'off' : 'on'}
			>
				<Box mb={1}>
					<Typography textAlign="left">
						{textValues.phoneNumberLabelText}
					</Typography>
				</Box>
				<Box mb={2}>{FormFields}</Box>
				<Box className={classes.submitButtonWrap}>
					<Button
						fullWidth
						variant="outlined"
						className={classes.submitButton}
						onClick={otpSent ? handleSubmit : sendOTP}
					>
						{otpSent
							? textValues.buttonTextAfterOTP
							: textValues.buttonTextBeforeOTP}
					</Button>
				</Box>
			</form>
			<Box className={classes.otpTextWrap}>{OTPSendText}</Box>
		</Box>
	);
}
export default React.memo(WithAuthUtils(FormSection));

FormSection.propTypes = {
	values: PropTypes.object,
	errorTexts: PropTypes.object,
	otpSent: PropTypes.bool,
	remainingTime: PropTypes.number,

	setNumber: PropTypes.func,
	setOTP: PropTypes.func,
	resendOTP: PropTypes.func,
	sendOTP: PropTypes.func,
	handleSubmit: PropTypes.func,
};
