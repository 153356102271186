import { useSelector } from 'react-redux';

// constants
import { textValues } from './constants';

// material
import { Box, LinearProgress, Typography } from '@mui/material';

// components
import ImageComponent from '@/components/widgets/image';

//util
import { getImageForUserModal } from './util';

//style
import useStyles from './style';

function TitleSection() {
	const { classes } = useStyles();

	const userModal = useSelector((state) => state.userModal);
	const isMobile = useSelector((state) => state.common.isMobile);
	const imageObject = getImageForUserModal(userModal.data.modalType);

	return (
		<Box className={classes.titleSectionContainer}>
			<Box className={classes.titleSectionOne}>
				<Box mb={isMobile ? 1 : 4}>
					<Typography variant="h5" color="primary" textAlign="center">
						{textValues.titleText}
					</Typography>
				</Box>

				{!isMobile && (
					<Box className={classes.imageWrap}>
						<ImageComponent
							layout="fill"
							src={imageObject.url}
							alt={imageObject.alternativeText}
						/>
					</Box>
				)}

				<Box>
					<Typography variant="h6" textAlign="center">
						{textValues.subTitleText}
					</Typography>
				</Box>
			</Box>

			<Box className={classes.titleSectionTwo}>
				{userModal.loading ? (
					<Box textAlign="center" width="100%">
						<LinearProgress color="primary" />
					</Box>
				) : (
					userModal.error && (
						<Typography color="error" textAlign="center">
							{userModal.error}
						</Typography>
					)
				)}
			</Box>
		</Box>
	);
}

export default React.memo(TitleSection);
